import {
  AdFeatureLevelFull,
  SellerType,
  SoldSectionAdVariant,
} from '../../../types';
import {
  BadgeColor,
  BadgeSize,
  BadgeStyle,
  MaskedContainerShape,
  MaskedContainerSize,
  GalleryType,
  BrandBannerSize,
  CardBannerUIType,
  CardContentUIType,
  CardGalleryUIType,
  CardLayout,
  Badge,
  MaskedContainer,
  renderSRPGalleryImages,
} from '@dist-property-frontend/toolkit';
import {
  SRPCardsPropsType,
  CardBannerPropsType,
  CardContentPropsType,
  CardGalleryPropsType,
  CardLayoutPropsType,
  PreparedCardData,
} from './srpCard.types';
import { SRPCardsDataTracking } from '../helpers/dataTracking';
import SRPCardContentTopRightSlot from '../SRPCardContentTopRightSlot';
import { getMetaProps } from '../helpers/listingMetaProps';
import SRPCardTitlePrice from '../SRPCardTitlePrice';

//HELPER FUNCTIONS

/**
 * Maps the AdFeatureLevelFull to the corresponding GalleryType.
 * This function can be reused for different card galleries, not only SRP
 * so it can be moved to a shared file if its needed!
 * @param type - The featured level type of the ad.
 * @param is_PRS_NH - Indicates if the ad is for PRS or New Home.
 * @returns {GalleryType} The corresponding GalleryType.
 */
export const mapAdFeatureLevelToGalleryType = (
  type: AdFeatureLevelFull,
  is_PRS_NH: boolean,
  isLimitedSoldSectionAd?: boolean,
): GalleryType => {
  if (
    (type === AdFeatureLevelFull.FEATURED ||
      type === AdFeatureLevelFull.PREMIER_PARTNER_FEATURED) &&
    is_PRS_NH
  ) {
    return GalleryType.LargeNoGrid;
  }
  if (isLimitedSoldSectionAd) {
    return GalleryType.SmallNoGrid;
  }
  const galleryTypeMapping: Record<AdFeatureLevelFull, GalleryType> = {
    [AdFeatureLevelFull.PREMIER_PARTNER_FEATURED]: GalleryType.LargeWithGrid,
    [AdFeatureLevelFull.PREMIER_PARTNER_PREMIUM]: GalleryType.MediumWithGrid,
    [AdFeatureLevelFull.PREMIER_PARTNER_LITE]: GalleryType.MediumNoGrid,
    [AdFeatureLevelFull.STANDARD]: GalleryType.SmallNoGrid,
    [AdFeatureLevelFull.FEATURED]: GalleryType.LargeWithGrid,
    [AdFeatureLevelFull.PREMIUM]: GalleryType.MediumNoGrid,
  };

  return galleryTypeMapping[type] || GalleryType.SmallNoGrid;
};

// MAIN FUNCTIONS

/**
 * Prepares the card banner data.
 * @param featuredLevelFull
 * @param cardBannerProps
 * @returns {CardBannerUIType | undefined}
 */
export const prepareCardBanner = (
  featuredLevelFull: AdFeatureLevelFull,
  cardBannerProps: CardBannerPropsType,
): CardBannerUIType | undefined => {
  //STANDARD / Private seller ads don't have a banner
  const earlyReturnCondition =
    featuredLevelFull === AdFeatureLevelFull.STANDARD ||
    cardBannerProps.sellerType === SellerType.PRIVATE ||
    cardBannerProps.soldContent === SoldSectionAdVariant.LIMITED; // Limited content ads on sold section don't have a banner

  if (earlyReturnCondition) {
    return undefined;
  }

  //Banner sizes
  const size =
    featuredLevelFull === AdFeatureLevelFull.PREMIER_PARTNER_FEATURED ||
    featuredLevelFull === AdFeatureLevelFull.FEATURED
      ? BrandBannerSize.MEDIUM
      : BrandBannerSize.SMALL;

  //Agent photo sizes
  const maskedContainerSize =
    featuredLevelFull === AdFeatureLevelFull.PREMIER_PARTNER_FEATURED ||
    featuredLevelFull === AdFeatureLevelFull.FEATURED
      ? {
          size: MaskedContainerSize.L72,
          mobileSize: MaskedContainerSize.M48,
          outline: true,
        }
      : {
          size: MaskedContainerSize.S40,
          mobileSize: MaskedContainerSize.S32,
          outline: true,
        };

  const cardBanner: CardBannerUIType = {
    rightMaskedImage: cardBannerProps?.profileImage,
    rightMaskedImageAlt: cardBannerProps?.profileImageAlt,
    rightTitle: cardBannerProps?.sellerName,
    rightSubtitle: cardBannerProps?.is_PRS_NH
      ? null
      : cardBannerProps?.sellerBranch,
    leftSlot: cardBannerProps?.standardLogo,
    fallbackText: cardBannerProps?.sellerBranch,
    backgroundColor: cardBannerProps?.backgroundColor,
    size: size,
    maskedContainerSize: maskedContainerSize,
    dataTracking: SRPCardsDataTracking.brandBanner,
  };

  return cardBanner;
};

/**
 * Prepares the card content data
 * @param featuredLevelFull
 * @param cardContentProps
 * @returns {CardContentUIType}
 */
export const prepareCardContent = (
  featuredLevelFull: AdFeatureLevelFull,
  cardContentProps: CardContentPropsType,
): CardContentUIType => {
  const PRSOrNewHomes = cardContentProps?.prsAd || cardContentProps?.newHomeAd;
  const titleTagline = cardContentProps?.tagline;
  const titlePrice = cardContentProps?.price;
  const subtitleFirstLine = cardContentProps.adTitle;
  const isSoldSection = cardContentProps?.isSoldSection;
  const soldPrice = cardContentProps?.soldPrice;
  const limitedStickerContent =
    cardContentProps?.soldContent === SoldSectionAdVariant.LIMITED
      ? 'Limited'
      : null;
  const isLimitedContent = cardContentProps?.soldContent === 'LIMITED';

  // if PRS/NEW_HOME, show nothing, otherwise follow the order below
  const metaProps = PRSOrNewHomes ? [] : getMetaProps(cardContentProps);

  const advantageLabel = [
    AdFeatureLevelFull.PREMIER_PARTNER_FEATURED,
    AdFeatureLevelFull.PREMIER_PARTNER_PREMIUM,
    AdFeatureLevelFull.PREMIER_PARTNER_LITE,
  ].includes(featuredLevelFull);

  const hideBottomSlot =
    featuredLevelFull !== AdFeatureLevelFull.STANDARD &&
    !cardContentProps.premierPartner;

  const cardContent: CardContentUIType = {
    titleTagline: titleTagline,
    titlePrice: (
      <SRPCardTitlePrice
        soldSection={isSoldSection}
        soldPrice={soldPrice}
        titlePrice={titlePrice}
        limitedContent={isLimitedContent}
      />
    ),
    subtitle: subtitleFirstLine,
    metaProps: limitedStickerContent ? null : metaProps,
    ber: limitedStickerContent ? null : cardContentProps?.berRating?.rating,
    viewings: cardContentProps?.viewings,
    sticker: limitedStickerContent ?? cardContentProps?.sticker,
    stickerTestId: 'overview-info-sticker',
    advantageLabel: Boolean(advantageLabel),
    //Removed Agent Name on bottom slot
    agentBranch: undefined,
    hideBottomSlot: hideBottomSlot,
    dataTracking: SRPCardsDataTracking.cardContent,
    topRightSlot: isSoldSection ? null : (
      <SRPCardContentTopRightSlot cardContentProps={cardContentProps} />
    ),
  };

  return cardContent;
};

/**
 * Prepares the card gallery
 * @param featuredLevelFull
 * @param cardGalleryProps
 * @returns {CardGalleryUIType}
 */
export const prepareCardGallery = (
  featuredLevelFull: AdFeatureLevelFull,
  cardGalleryProps: CardGalleryPropsType,
) => {
  const {
    offers,
    label,
    squareLogo,
    squareLogoAlt,
    images,
    isMapView,
    totalImages,
    isSoldSection,
    soldContent,
  } = cardGalleryProps || {};

  const isLimitedSoldSectionAd = soldContent === SoldSectionAdVariant.LIMITED;

  const galleryType: GalleryType = mapAdFeatureLevelToGalleryType(
    featuredLevelFull,
    cardGalleryProps.is_PRS_NH,
    isLimitedSoldSectionAd,
  );

  /**
   *
   *  No badge if label, offers and isSoldSection are not provided.
   *  If only label is provided, it returns the label text in uppercase
   *  If offers are provided, it checks the status:
   *    - If 'CLOSED', it returns 'OFFER ACCEPTED'
   *    - Else, it generates a text based on the number of offers (offersCount).
   *  Applies a different style for online offers.
   * If isSoldSection is provided, it returns 'SOLD' with a red badge.
   */
  const getBottomLeftSlot = () => {
    if (!label && !offers && !isSoldSection) return undefined;

    if (!offers && !isSoldSection) {
      return (
        <Badge
          badgeSize={BadgeSize.SMALL}
          badgeColor={BadgeColor.GREY_DARKER}
          badgeStyle={BadgeStyle.FILLED_DARK}
          data-tracking={SRPCardsDataTracking['bottomLeft']}
        >
          {label?.toUpperCase()}
        </Badge>
      );
    }

    if (offers && offers.status === 'CLOSED') {
      return (
        <Badge
          badgeSize={BadgeSize.SMALL}
          badgeColor={BadgeColor.GREY_DARK}
          badgeStyle={BadgeStyle.FILLED_DARK}
          data-tracking={SRPCardsDataTracking['bottomLeft']}
        >
          OFFER ACCEPTED
        </Badge>
      );
    }

    if (offers && offers.status !== 'CLOSED' && offers.status !== 'CANCELLED') {
      const hasOffers = offers && offers?.offersCount > 0;
      const offerCountText = hasOffers ? `${offers.offersCount} ` : '';
      const offerText =
        hasOffers && offers.offersCount === 1
          ? 'ONLINE OFFER'
          : 'ONLINE OFFERS';
      return (
        <Badge
          badgeSize={BadgeSize.SMALL}
          badgeColor={BadgeColor.BLUE}
          badgeStyle={BadgeStyle.FILLED_DARK}
          data-tracking={SRPCardsDataTracking['bottomLeft']}
        >
          {`${offerCountText}${offerText}`}
        </Badge>
      );
    }

    if (isSoldSection) {
      const soldBadgeText = `SOLD ${cardGalleryProps?.soldDate}`;
      return (
        <Badge
          badgeSize={BadgeSize.MEDIUM}
          badgeColor={BadgeColor.RED}
          badgeStyle={BadgeStyle.FILLED_DARK}
          data-tracking={SRPCardsDataTracking['bottomLeft']}
        >
          {`${soldBadgeText}`}
        </Badge>
      );
    }
  };

  const getBottomRightSlot = () => {
    //Only STANDARD have square logo
    if (featuredLevelFull !== AdFeatureLevelFull.STANDARD) {
      return undefined;
    }

    const brandingBottomRight =
      squareLogo && !isSoldSection ? (
        <MaskedContainer
          imageAlt={squareLogoAlt}
          image={squareLogo}
          size={MaskedContainerSize.S40}
          shape={MaskedContainerShape.SQUARE}
          mobileSize={MaskedContainerSize.S40}
          data-tracking={SRPCardsDataTracking['bottomRight']}
        />
      ) : null;
    return brandingBottomRight;
  };

  const slots = {
    bottomLeft: getBottomLeftSlot(),
    bottomRight: getBottomRightSlot(),
  };
  const imageLoading = cardGalleryProps?.isLoadEagerly ? 'eager' : 'lazy';

  // If the ad is STANDARD, we only display the first image
  const firstImage = images.length > 0 ? [images[0]] : [];

  const blurredFallBackImage = `/static/images/fallbacks/blur_image_fallback_small.jpg`;

  /**
   * Here we decide the images that will be displayed in the gallery
   * They must be wrapped in a container, gallery expects JSX.Element
   * Add a blur effect and a 'View all photos' button when the condition is met
   */
  const galleryImages = renderSRPGalleryImages({
    images:
      featuredLevelFull === AdFeatureLevelFull.STANDARD ? firstImage : images,
    totalImages:
      featuredLevelFull === AdFeatureLevelFull.STANDARD ? 1 : totalImages,
    imageLoading,
  });

  const galleryProps: CardGalleryUIType = {
    children: galleryImages ?? [],
    slots: slots,
    hasIndicator: Boolean(featuredLevelFull !== AdFeatureLevelFull.STANDARD),
    imageGridImages: images,
    hoverInteraction: true,
    hasSlideAnimation: true,
    galleryType: galleryType,
    forceMobileView: isMapView ?? false,
    fallbackImage: blurredFallBackImage,
  };

  return galleryProps;
};

/**
 * Prepares the card layout (horizontal or vertical)
 * @param featuredLevelFull
 * @param cardLayoutProps
 * @returns {CardLayout}
 */
export const prepareLayout = (
  featuredLevelFull: AdFeatureLevelFull,
  cardLayoutProps: CardLayoutPropsType,
) => {
  const isLimitedSoldSectionAd =
    cardLayoutProps?.soldContent === SoldSectionAdVariant.LIMITED;

  const isVerticalLayout =
    !isLimitedSoldSectionAd &&
    (cardLayoutProps.isMobile ||
      cardLayoutProps.isMapView ||
      [
        AdFeatureLevelFull.FEATURED,
        AdFeatureLevelFull.PREMIER_PARTNER_FEATURED,
      ].includes(featuredLevelFull));

  return isVerticalLayout
    ? CardLayout.VerticalDesktop
    : CardLayout.HorizontalDesktop;
};

/**
 * Prepares the card with all components data
 * @param srpCardsProps
 * @returns {PreparedCardData}
 */
export const prepareCardData = (
  srpCardsProps: SRPCardsPropsType,
): PreparedCardData => {
  const cardBanner = prepareCardBanner(
    srpCardsProps.featuredLevelFull,
    srpCardsProps.cardBannerProps,
  );

  const cardGallery = prepareCardGallery(
    srpCardsProps.featuredLevelFull,
    srpCardsProps.cardGalleryProps,
  );

  const cardContent = prepareCardContent(srpCardsProps.featuredLevelFull, {
    ...srpCardsProps.cardContentProps,
    onSaveButtonClick: srpCardsProps.onSaveButtonClick,
    isSaved: srpCardsProps.isSaved,
    id: srpCardsProps.id,
  });

  const layout = prepareLayout(
    srpCardsProps.featuredLevelFull,
    srpCardsProps.cardLayoutProps,
  );

  return {
    layout,
    cardBanner,
    cardGallery,
    cardContent,
    hasSubunits: srpCardsProps.cardSubunitsProps?.length > 0,
  };
};
